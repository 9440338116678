<template>
  <div id="app">
    <keep-alive :include="cache">
      <router-view />
    </keep-alive>
    <common-tab-bar v-if="$route.name && ((!isWeiXin() && user) || isWeiXin())"></common-tab-bar>
    <!-- <ke-fu v-if="unShowKeFu.indexOf($route.name) == -1"></ke-fu> -->
    <ke-fu v-if="user"></ke-fu>
    <Holiday v-if="user" style="width:75%" v-model="showHoliday"></Holiday>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import CommonTabBar from "@/components/tabbar.vue";
import KeFu from "@/components/kefu.vue"
import Holiday from "@/components/holiday-popup.vue"
import {config, getWxLocation} from '@/lib/wx'
import { getAppLocation } from '@/lib/app.js'
import { isWeiXin } from '@/lib/utils'

export default {
  components: {
    CommonTabBar,
    KeFu,
    Holiday
  },
  data() {
    return {
      routerFrom: null,
      unShowKeFu: ['auth'],
      showHoliday: false
    }
  },
  // mounted() {
  //   this.$store.commit('routerData/setCache');
  //   this.setShowHoliday();
  // },
  async mounted() {
    this.$store.commit('routerData/setCache');
    localStorage.setItem("customize_id", '');
    if (isWeiXin()) {
      localStorage.setItem("APPFIRSTURL", window.location.href.split("#")[0])
      await this.config();
      setTimeout(() => {
        if (this.currentLocation.latitude == '0.0000000000') {
          this.getWxLocation()
        }
      }, 1500)
    } else {
      // app
      if (this.currentLocation.latitude == '0.0000000000') {
        setTimeout(() => {
          this.getAppLocation();
        }, 1000)
      }
    }
    this.setShowHoliday();
  },
  provide() {
    return {
      _app: this,
    };
  },
  methods: {
    config,
    getWxLocation,
    getAppLocation,
    isWeiXin,
    //刷新,该方法对同路由间跳转无效
    reload(name = null) {
      if (name == null) name = this.$route.name;
      if (this.cache.indexOf(name) != -1) { //在缓存里面才刷新，不在缓存里面等会addCache把非缓存页面变成缓存页面
        this.$store.commit('routerData/removeCache', name);
        this.$nextTick(() => {
          this.$store.commit('routerData/addCache', name);
        })
      }

    },
    //刷新上一页，如果存在，该方法对同路由间跳转无效，这个routerFrom没判断前进后退！！！
    reloadPrev() {
      if (this.routerFrom != null) {
        let name = this.routerFrom.name;
        this.reload(name);
      }
    },
    setShowHoliday() {
      let holiday = localStorage.getItem("holiday_20250122");
      if (!holiday) {
        let day = new Date().getTime();
        let start = new Date('2025/01/22 00:00:00').getTime();
        let end = new Date('2025/02/05 00:00:00').getTime();
        if (start <= day && day < end) {
          this.showHoliday = true
          return 
        }
      }
      this.showHoliday = false
    }
  },
  computed: {
    ...mapState({
      cache: state => state.routerData.cache,
      user: state => state.user.user,
      currentLocation: state=> state.wx.location
    })
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        this.routerFrom = from;
        if (to.meta?.cache && to.query._t >= from.query?._t) {
          this.reload();
        }
      }
    }
  }
}
</script>
<style lang="scss">
body {
  margin: 0;
  padding: 0;
  font-family: PingFang SC;
  background: #f6f6f6;
  overscroll-behavior-y: none;
  p{
    margin: 0;
  }
  // overflow: hidden;
}
 ::-webkit-scrollbar {
  display:none;
}
</style>
